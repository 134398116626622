const Cookie = process.client ? require('js-cookie') : undefined
export const state = () => ({
  userInfo: {},
  userProfile: {},
  userCostcenter: {},
  profileType: '',
  changeSitePermission: false,
  consentCookieStatus: '',
})

export const mutations = {
  SET_USER_INFO(state, { payload }) {
    state.userInfo = payload
    if (Cookie !== undefined) {
      Cookie.set('userInfo', payload, {
        expires: 1,
        sameSite: 'none',
        secure: true,
      })
      Cookie.remove('OnBoard')
    }
  },
  SET_USER_PROFILE(state, { payload, profileType }) {
    if (payload !== undefined && profileType !== undefined) {
      state.userProfile = payload
      state.profileType = profileType
      let data = Object.assign({}, payload)
      delete data['name']
      data = JSON.stringify(data)
      if (Cookie !== undefined) {
        Cookie.set('userProfile', data, {
          expires: 1,
          sameSite: 'none',
          secure: true,
        })
        Cookie.set('profileType', profileType, {
          expires: 1,
          sameSite: 'none',
          secure: true,
        })
      }
    }
  },
  SET_USER_COSTCENTER(state, { payload }) {
    let data = JSON.stringify(payload)
    state.userCostcenter = payload
    if (Cookie !== undefined) {
      Cookie.set('userCostcenter', data, {
        expires: 1,
        sameSite: 'none',
        secure: true,
      })
    }
  },
  SET_CONSENT_COOKIE_STATUS(state, payload) {
    state.consentCookieStatus = payload
    if (Cookie !== undefined) {
      Cookie.set('consentCookieStatus', payload, {
        expires: 365,
        sameSite: 'none',
        secure: true,
      })
    }
  },
  LOAD_USER_PROFILE(state, { payload, profileType }) {
    let data = payload !== undefined ? JSON.parse(payload) : payload
    state.userProfile = data
    state.profileType = profileType
  },
  LOAD_USER_COSTCENTER(state, { payload }) {
    let data = payload !== undefined ? JSON.parse(payload) : payload
    state.userCostcenter = data
  },
  LOAD_USER_INFO(state, { payload }) {
    let data = payload !== undefined ? JSON.parse(payload) : payload
    state.userInfo = data
  },
  LOAD_CONSENT_COOKIE_STATUS(state, payload) {
    state.consentCookieStatus = payload
  },
  REMOVE_USER_PROFILE(state) {
    state.userProfile = {}
    state.userInfo = {}
    state.userCostcenter = {}
    state.changeSitePermission = false
    if (Cookie !== undefined) {
      Cookie.remove('userProfile')
      Cookie.remove('profileType')
      Cookie.remove('userCostcenter')
      Cookie.remove('userInfo')
    }
  },
  SET_CHANGE_SITE_PERMISSION(state, { payload }) {
    state.changeSitePermission = payload
  },
  SET_USER_PROFILE_IS_BANNER(state, payload) {
    if (payload !== undefined && state.userProfile !== undefined) {
      state.userProfile.is_show_banner_promotion = payload
      let data = JSON.stringify(state.userProfile)
      if (Cookie !== undefined) {
        Cookie.set('userProfile', data, {
          expires: 1,
          sameSite: 'none',
          secure: true,
        })
      }
    }
  },
  SET_COMPANY_LOGO(state, url) {
    state.userProfile.company_logo_url = url
    let data = JSON.stringify(state.userProfile)
    if (Cookie !== undefined) {
      Cookie.set('userProfile', data, {
        expires: 1,
        sameSite: 'none',
        secure: true,
      })
    }
  },
  SET_USER_IMAGE(state, url) {
    state.userInfo.profile_image_url = url
    let data = JSON.stringify(state.userInfo)
    Cookie.set('userInfo', data, {
      expires: 1,
      sameSite: 'none',
      secure: true,
    })
  },
  SET_USER_NAME(state, payload) {
    state.userInfo.first_name = payload.firstName
    state.userInfo.last_name = payload.lastName
    let data = JSON.stringify(state.userInfo)
    Cookie.set('userInfo', data, {
      expires: 1,
      sameSite: 'none',
      secure: true,
    })
  },
  SET_COMPANY_NAME(state, payload) {
    state.userProfile.name = payload
    let data = JSON.stringify(state.userProfile)
    Cookie.set('userProfile', data, {
      expires: 1,
      sameSite: 'none',
      secure: true,
    })
  },

  SET_USER_PROMOTION_TRACK(state, payload) {
    localStorage.setItem('userPromotionTrack', JSON.stringify(payload))
  },
}

export const actions = {
  setUserInfo({ commit }, { payload }) {
    commit('SET_USER_INFO', { payload })
  },
  setUserProfile({ commit }, { payload, profileType }) {
    commit('SET_USER_PROFILE', { payload, profileType })
  },
  setUserInfoName({ commit }, payload) {
    commit('SET_USER_NAME', payload)
  },
  setUserProfileImage({ commit }, payload) {
    commit('SET_USER_IMAGE', payload)
  },
  setUserCostcenter({ commit }, { payload }) {
    commit('SET_USER_COSTCENTER', { payload })
  },
  removeUserProfile({ commit }) {
    commit('REMOVE_USER_PROFILE')
  },
  setChangeSitePermision({ commit }, { payload }) {
    commit('SET_CHANGE_SITE_PERMISSION', { payload })
  },
  setConsentCookieStatus({ commit }, payload) {
    commit('SET_CONSENT_COOKIE_STATUS', payload)
  },
  setUserProfileISBanner({ commit }, payload) {
    commit('SET_USER_PROFILE_IS_BANNER', payload)
  },
  setCompanyLogo({ commit }, payload) {
    commit('SET_COMPANY_LOGO', payload)
  },
  setCompanyName({ commit }, payload) {
    commit('SET_COMPANY_NAME', payload)
  },
}

export const getters = {
  userInfo: (state) => state.userInfo,
  userProfile: (state) => state.userProfile,
  userCostcenter: (state) => state.userCostcenter,
  profileType: (state) => state.profileType,
  changeSitePermission: (state) => state.changeSitePermission,
  consentCookieStatus: (state) => state.consentCookieStatus,
  userInfoTrack: (state) => ({
    customerType: state.profileType || '',
    customerID:
      state.profileType === 'standard'
        ? state.userProfile?.offline_id || ''
        : state.userProfile?.company_code || '',
    customerLevel: state.userProfile.segment || '',
    customerEmail: state.userInfo.email || '',
    userOpenID: state.userInfo?.user_open_id
      ? state.userInfo?.user_open_id
      : Cookie !== undefined
      ? Cookie.get('guestId')
      : '',
    // ? state.profileType === 'standard'
    //   ? state.userInfo
    //       ?.user_open_id + '_' + state.userProfile?.offline_id
    //   : state.userInfo
    //       ?.user_open_id + '_' + state.userProfile?.company_code
    // : Cookie !== undefined
    // ? Cookie.get('guestId')
    // : '',
  }),
  userPromotionTrack: () =>
    JSON.parse(localStorage.getItem('userPromotionTrack')) || {},
}
