import * as core from '@/plugins/service/core'

export const shoppingCartProvider = {
  async addCart(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/ShoppingCart/addCart`,
      payload
    )
    return data
  },
  async createOrderTemporary(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary`,
      payload
    )
    return data
  },
  async getOrderTemporary(tempId, mode = 'view', loadingSatate = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}?mode=${mode}`,
      null,
      null,
      loadingSatate
    )
    return data
  },
  async uploadPOFile(tempId, apiData, config, showLoading = true) {
    const data = core.upload(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}/files`,
      apiData,
      config,
      showLoading
    )
    return data
  },
  async updateOrderTemporaryShipping(tempId, shipId) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}/shipping/${shipId}`,
      JSON.stringify({})
    )
    return data
  },
  async updateOrderTemporaryInvoice(tempId, invoiceId) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}/invoice/${invoiceId}`,
      JSON.stringify({})
    )
    return data
  },
  async updateOrderTemporaryDetails(tempId, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}`,
      body
    )
    return data
  },
  async updateOrderTemporaryPayment(tempId, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}/payment`,
      body,
      null,
      false
    )
    return data
  },
  async createOrderTemporaryFromDocument(orderObjectId) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/document/${orderObjectId}`,
      JSON.stringify({})
    )
    return data
  },
  async updateOrderTemporaryQty(tempId, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}/qty`,
      body
    )
    return data
  },
  async removeOrderTemporaryItem(tempId, skuCode) {
    const data = core.remove(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}/items/${skuCode}`
    )
    return data
  },
  async createOrderTemporaryItem(tempId, body) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}/item`,
      body
    )
    return data
  },
  async reCalulateOrderTemporaryCreditCard(
    tempId,
    params,
    loadingSatate = true
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/OrdersTemporary/${tempId}/recalulate/creditcard/promotion`,
      params,
      null,
      loadingSatate
    )
    return data
  },
  async getRecommendPromotionQuantity(skuCode, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v2/Promotions/quantity/recommend/${skuCode}`,
      params,
      null,
      false
    )
    return data
  },
  async getPromotionDiscountpdp(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v2/promotions/discount`,
      params,
      null,
      false
    )
    return data
  },
  async getPaymentCreditCardPromotionsOrderTemporary(tempId, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/Promotions/2c2p/CreditCard/OrdersTemporary/${tempId}`,
      params
    )
    return data
  },
  async getPaymentCreditCardPromotionsOrderDocument(orderId, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/Promotions/2c2p/CreditCard/Orders/${orderId}`,
      params
    )
    return data
  },
  async addCart(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/ShoppingCarts`,
      payload
    )
    return data
  },
  async getCalculateCart(
    userOpenId,
    profileId,
    accountChannel,
    costcenterId,
    voucherNo,
    showLoading
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/ShoppingCarts/${userOpenId}-${profileId}/Calculate?account_channel=${accountChannel}&costcenter_id=${costcenterId}&voucher_no=${voucherNo}`,
      null,
      null,
      showLoading
    )
    return data
  },
  async getCartCount(userOpenId, profileId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v2/ShoppingCarts/${userOpenId}-${profileId}/count`,
      null,
      null,
      false
    )
    return data
  },
  async getCartItems(userOpenId, profileId, accountChannel) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v2/ShoppingCarts/${userOpenId}-${profileId}/?account_channel=${accountChannel}`,
      null,
      null,
      false
    )
    return data
  },
  async updateCartItemQty(body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/ShoppingCarts/Qty`,
      body
    )
    return data
  },
  async updateCheckoutCartItem(body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/ShoppingCarts/Checkout`,
      body
    )
    return data
  },
  async removeCartItemQty(body) {
    body = JSON.parse(body)
    const data = core.remove(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/ShoppingCarts/${body.user_open_id}-${body.profile_id}/skus/${body.sku}?account_channel=${body.account_channel}`
    )
    return data
  },
  // GetCouponByCode
  async getCouponByCode(code, params) {
    // {
    //   "account_id": "6523bd7c0e2516d6e088d839",
    //   "account_channel": "standard", //<option: "standard", "eprocurement">
    //   "user_category_type": "Standard", //<option: "Standard", "Company", "Costcenter">
    // }
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/me/promotions/coupons/${code}`,
      params,
      null,
      false
    )
    return data
  },
  // GetCouponsSuggestion
  async getCouponsSuggestion(params) {
    // {
    //   "account_id": "6523bd7c0e2516d6e088d839",
    //   "account_channel": "standard", //<option: "standard", "eprocurement">
    //   "user_category_type": "Standard", //<option: "Standard", "Company", "Costcenter">
    //   "page": 1,
    //   "page_size": 6, // <range: 4-150>
    //   "coupon_types" : [] // <optional><options:"Platform","Seller","Brand","NewCustomer">
    // }
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_SHOPPING_CART}api/v1/me/promotions/suggest-coupons`,
      params,
      null,
      false
    )
    return data
  },
}
export default (context, inject) => {
  inject('shoppingCartProvider', shoppingCartProvider)
}
