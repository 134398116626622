export default ({ $axios, app, store, redirect }, inject) => {
  // Create an Axios instance with the base URL and necessary headers
  const api = $axios.create({
    baseURL: process.env.NUXT_ENV_DIRECTUS_URL,
    headers: {
      common: {
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${process.env.NUXT_ENV_DIRECTUS_KEY}`,
      },
      post: {
        'Content-Type': 'application/json',
      },
    },
  })

  // https://docs.directus.io/reference/query.html
  function params(payload) {
    let searchParams = new URLSearchParams()
    if (payload?.fields)
      searchParams.append('fields', payload.fields.replace(/\s+/g, ''))
    if (payload?.filter)
      searchParams.append('filter', JSON.stringify(payload.filter))
    if (payload?.deep) searchParams.append('deep', JSON.stringify(payload.deep))
    if (payload?.sort)
      searchParams.append('sort', payload.sort.replace(/\s+/g, ''))
    if (payload?.limit) searchParams.append('limit', payload.limit)

    return searchParams
  }

  const directusProvider = {
    async onGetInstories(payload) {
      const response = await api.get('items/instories', {
        params: params(payload),
      })
      return response.data
    },
  }

  const directusImg = (payload, option) => {
    let img = `${process.env.NUXT_ENV_DIRECTUS_ASSETS}/${payload.id}/${payload.filename_download}?format=webp`
    return option
      ? `${img}${genSrcset(option.w, option.h, option.q)}`
      : `${img}`
  }
  const genSrcset = (w, h, q) => {
    return `&width=${w}&height=${h}&quality=${q}`
  }

  const directusSrcSet = (
    payload,
    options = [{ w: 150, h: 150, ws: '150w' }]
  ) => {
    let srcsets = []
    if (options.length > 0) {
      for (const element of options) {
        let pathtemp = `${process.env.NUXT_ENV_DIRECTUS_ASSETS}/${payload.id}/${payload.filename_download}?format=webp`

        const option = element

        pathtemp = `${pathtemp}${genSrcset(option.w, option.h, option.q)} ${
          option.ws
        }`

        srcsets = [...srcsets, pathtemp]
      }
    }
    console.log(
      'directusSrcSet',
      srcsets.length ? srcsets.join(',') : `${pathtemp}`
    )
    return srcsets.length ? srcsets.join(',') : `${pathtemp}`
  }

  inject('directusProvider', directusProvider)
  inject('directusImg', directusImg)
  inject('directusSrcSet', directusSrcSet)
}
